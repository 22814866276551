import React from 'react';
import PostTitle from './PostTitle';
import PostEntryMeta from './PostEntryMeta';
import PostThumbnail from './PostThumbnail';

const PostEntry = ({ post }) => {
  const excerpt = post.excerpt
    ? post.excerpt
    : `${post.content &&
        post.content
          .split(' ')
          .slice(0, 25)
          .join(' ')} ...`;

  return (
    <article className="post type-post lsco-post">
      {post.featuredImage && <PostThumbnail post={post} location="blog" width="760" height="480" />}
      <header className="entry-header">
        <PostTitle
        location="blog"
        post={post}
        titleClass="entry-title h5"
        />
        <PostEntryMeta post={post} location="blog" />
      </header>
      <div
        className="entry-content"
        dangerouslySetInnerHTML={{
          __html: excerpt,
        }}
      />
    </article>
  );
};

export default PostEntry;
