import React from "react"
//import { Link } from 'gatsby'
//import { FiChevronLeft, FiChevronRight } from 'react-icons/fi';

const ArchivePagination = ({ pagenumber, TotalPages, slug }) => {
  
  pagenumber = parseInt(pagenumber)
  TotalPages = parseInt(TotalPages)

  const pageNumbers = [];

  for (let i = 1; i <= TotalPages; i++) {
    pageNumbers.push(i);
  }

  return (
    <nav className="pagination navigation" role="navigation">
      <h2 className="screen-reader-text">Posts navigation</h2>
      <br />
      <ul className="pagination nav-links">
        {pagenumber > 1 && (
          <li className="page-item">
            <a className="page-link" href={`/category/${slug}/page/${pagenumber - 1}`}>
            Previous Page
            </a>
          </li>
        )}

        {pageNumbers.map(number => (
            <li key={number} className={`page-item ${number === pagenumber ? ('active') : ('')}`}>
              {number !== pagenumber ? (
              <a className="page-link" href={number === 1 ? (`/category/${slug}/`) : (`/category/${slug}/page/${number}`)}>
                {number}
              </a>
              ) : (
              <span className="page-link">
                {number}
              </span>
              )}
            </li>
        ))}

        {pagenumber < TotalPages && (
          <li className="page-item">
            <a className="page-link" href={`/category/${slug}/page/${pagenumber + 1}`}>
            Next Page
            </a>
          </li>
        )}
      </ul>
    </nav>
  );
};

export default ArchivePagination