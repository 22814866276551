import React from "react"
import Layout from "../../components/Layout"
import PostArchive from "../../components/PostArchive"
import SEO from '../../components/SEO';
import HeaderArchive from '../../components/HeaderArchive';
import ArchivePagination from '../../components/ArchivePagination';

const config = require('../../../config');
const algoliasearch = require('algoliasearch');
const client = algoliasearch(config.AlgoliaAppId, config.AlgoliaApiKey);
const index = client.initIndex(config.AlgoliaIndexName);

class CategoryArchive extends React.Component {
  _isMounted = false;
  constructor(props) {
    super(props);
    
    // Grab pagenumber from path
    var CurrentPath = this.props.location.pathname;
    var segment_array = CurrentPath.split( '/' );
    var pagenum = segment_array.pop();
    // Set empty pagenumber to 1
    if(pagenum && !isNaN(pagenum)){
    } else {
      pagenum = 1
    }
    //console.log(pagenum)
    
    this.state = {
      loading: true,
      error: false,
      name: this.props.pageContext.name,
      slug: this.props.pageContext.slug,
      itemsPerPage: this.props.pageContext.itemsPerPage,
      pagenumber: pagenum,
      posts: '',
      TotalPosts: '',
      TotalPages: ''
    };
  }

  componentDidMount() {
    this._isMounted = true;
    index.search(
    {
      hitsPerPage: this.state.itemsPerPage,
      page: this.state.pagenumber - 1,
      facetFilters: [ [ `taxonomies.category: ${this.state.name}` ] ]
    })
    .then(data => {
      if (this._isMounted) {
      this.setState({
        posts: data.hits,
        TotalPosts: data.nbHits,
        TotalPages: data.nbPages,
        loading: false
      })
      }
    })
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  render() {
    const loading = this.state.loading
    const name = this.state.name
    const slug = this.state.slug
    const pagenumber = this.state.pagenumber
    const TotalPosts = this.state.TotalPosts
    const TotalPages = this.state.TotalPages
    const posts = this.state.posts

    return (
      <Layout>
        <SEO
          title={`Category - ${name}`}
          description={`A collection of posts from the ${name} category.`}
        />
        <section className="block-section block-latest layout-fullwidth">
            <div className="row-full">
              <div className="container">
                <div className="row lsco-block py-5">
                
                  <HeaderArchive name={name} />

                  <div id="primary" className="content-area">
                    <main id="main" className="site-main">
                      <div className="post-archive">
                        <div className="archive-main row">
                          {loading ? (
                            <p>Loading …</p>
                          ) : (
                          posts &&
                          posts.map(post => {
                            return (
                              <div key={post.id} className="columns col-sm-4">
                                <PostArchive post={post} />
                              </div>
                              );
                          })
                          )}
                        </div>{/* End of .archive-main */}
                        <ArchivePagination
                          pagenumber={pagenumber}
                          TotalPages={TotalPages}
                          slug={slug}
                          TotalPosts={TotalPosts}
                        />
                      </div>{/* End of .post-archive */}
                    </main>{/* End of #main */}
                  </div>{/* End of #primary */}

                </div>{/* End of .lsco-block */}
              </div>{/* End of .container */}
            </div>{/* End of .row-full */}
          </section>
      </Layout>
    )
  }
}

export default CategoryArchive