import React from "react"
import { Link } from "gatsby"

const WithLink = ({ post, location, children }) =>
  location === "single"   ? (
    children
  ) : (
    <Link
      className="post-thumbnail"
      to={`${post.uri}`}
      aria-hidden="true"
    >
      {children}
    </Link>
  )


const PostThumbnail = ({ post, location, width, height }) => {
  const imgUrl = post.featuredImage.node.sourceUrl.substring(0, post.featuredImage.node.sourceUrl.indexOf('?'));
  const lowresImg = imgUrl+'?resize='+width/50+'%2C'+height/50+'&ssl=1';
  const highresImg = imgUrl+'?resize='+width+'%2C'+height+'&ssl=1';
  return (
  <div className="post-thumbnail">
    <div className="post-thumb">
      <WithLink location={location} post={post}>
      <img src={lowresImg} data-src={highresImg} className="lazyload blur-up" alt={post.title}  />
      </WithLink>
    </div>

    <img src={lowresImg} data-src={highresImg} className="image-glow-clone lazyload" alt={post.title}  />
  </div>
  );
};

export default PostThumbnail
