import React from 'react';

const HeaderArchive = ({ name }) => (
  <header className="page-header page-header__archive container bottom-spacer">
    <p className="small text-muted mb-2">Browsing category</p>
    <h1 className="page-title h4 mb-4" dangerouslySetInnerHTML={{ __html: name, }} />
  </header>
);

export default HeaderArchive;
